import UiRouterModule, { StateProvider } from '@uirouter/angularjs';
import AccountResourceModule from 'modules/resources/account';
import * as angular from 'angular';
import 'angular-growl-v2';
import { InternationalPhoneModule } from 'modules/international-phone';
import { MyAccountCompanyController } from './company-ctrl';
// @ts-ignore
import MyAccountCompanyTemplate from './company.html';

const ctrl = 'MyAccountCompanyCtrl';

export default angular.module('app.accounts.my.company', [
  InternationalPhoneModule,
  UiRouterModule,
  AccountResourceModule,
  'angular-growl'
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('accounts.my.company', {
      url: '/company',
      views: {
        account: {
          template: MyAccountCompanyTemplate,
          controller: ctrl
        }
      },
      data: {
        title: 'Company'
      }
    });
  })
  .controller(ctrl, MyAccountCompanyController)
  .name;
