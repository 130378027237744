import { UserManagerService } from '../../../user-manager/user-manager.service';

export const MyAccountCompanyController = (
  $scope: any,
  growl: ng.growl.IGrowlService,
  UserManager: UserManagerService
) => {
  'ngInject';

  $scope.account = UserManager.data;

  $scope.onSuccess = () => {
    growl.success('Your company details were updated');
  };
};
